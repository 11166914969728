<template>
  <component
    :is="tag"
    :id="labelUniqueId"
    :for="(modelValue.labelAttrs && modelValue.labelAttrs.for) || widgetUniqueId"
    :class="[
      $style.formLabel,
      {
        [$style.formLabelSelect]: isSelect,
        [$style.formLabelFocused]: isFocused || modelValue.widgetAttrs.placeholder,
        [$style.formLabelDisabled]: isDisabled,
        [$style.formLabelFreezed]: isFreezed,
        [$style.formLabelFilled]: modelValue.model || modelValue.model === 0 || modelValue.model === '0',
        [$style.formLabelError]: hasError,
        [$style.formLabelSuccess]: hasSuccess,
        [$style.formLabelIconAfter]: modelValue.widgetIconAttrs && isIconAfter,
        [$style.formLabelIconBefore]: modelValue.widgetIconAttrs && !isIconAfter,
        [$style.formLabelVersion2]: modelValue.version2
      }
    ]"
    v-bind="modelValue.labelAttrs"
  >
    <span v-html="labelValue" />
  </component>
</template>

<script>
  import formMixin from '~~/common/mixins/formMixin';

  export default {
    name: 'AwFormLabel',
    mixins: [formMixin],
    props: {
      tag: {
        type: [String, Object],
        default: 'label',
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isSelect () {
        return this.modelValue.type === 'select';
      },
      isFreezed () {
        const mergedProps = {
          ...this.modelValue.widgetProps,
          ...this.modelValue.widgetAttrs,
        };
        return ['prefix', 'postfix'].some(pfix => {
          return Boolean((pfix in mergedProps) ||
            this.$parent.$slots[pfix]?.()?.[0]?.children?.length,
          );
        });
      },
      isIconAfter () {
        return this.modelValue.widgetIconAttrs?.position === 'after';
      },
      isDisabled () {
        return this.modelValue.widgetAttrs?.disabled;
      },

    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.formLabel {
  --spacing: 8px;
  @include font(null, 17px, 24px);
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 0;
  bottom: auto;
  left: 0;
  display: block;
  overflow: hidden;
  padding: 0 16px;
  cursor: text;
  user-select: none;
  transition: all $animation-speed-fast $animation-timing-function;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: $color-boulder;

  &Select {
    --spacing: 3px;
  }

  &Filled,
  &Freezed,
  &Focused {
    @include font(null, 10px, 16px);
    top: var(--spacing);
  }

  &Error {
    color: $color-error-v1-text;
  }

  &Success {
    color: $color-success;
  }

  &Disabled {
    cursor: not-allowed;
  }

  &IconBefore {
    padding-left: 52px;
  }

  &IconAfter {
    padding-right: 52px;
  }

  &Version2 {
    &.formLabel {
      @include font(500, $font-size-md, 20px);
      color: $color-text-secondary;

      &Error {
        color: $color-error-v2-text;
      }

      &Success {
        color: $color-text-secondary;
      }

      &Disabled {
        color: $color-text-tertiary;
      }

      &Filled,
      &Freezed,
      &Focused {
        @include font(null, 12px, 16px);
        top: var(--spacing);
      }
    }
  }
}
</style>
