<template>
  <div
    v-bind="awAttrsNoListeners"
    data-form-widget
    :class="[$style.formWidget, $style[`formWidget${modelValue.type}`], {[$style[modelValue.widgetDisplay]]: modelValue.widgetDisplay}]"
  >
    <slot name="before" />
    <component
      :is="currentComponent.component"
      v-if="currentComponent"
      v-bind="awListeners"
      ref="formComponent"
      v-model="modelValueData"
      :is-focused="isFocused"
      :show-form-row-label="showFormRowLabel"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>
      <template #postfix>
        <slot name="postfix" />
      </template>
    </component>
    <slot name="after" />
  </div>
</template>

<script>
  import { LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import formMixin from '~~/common/mixins/formMixin';
  import awListenersMixin from '~~/common/mixins/awListenersMixin.js';

  export default {
    name: 'AwFormWidget',
    components: {
      LvIcon,
    },
    mixins: [
      formMixin,
      awListenersMixin,
    ],
    inheritAttrs: false,
    props: {
      isFocused: {
        type: Boolean,
        default: false,
      },
      showFormRowLabel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      currentComponent () {
        const component = this.$appConfig.SimpleWidgetTypeConfig[this.modelValue.type];
        if (!component) {
          this.$logger.warn(`There is no template found for '${this.modelValue.type}' simple widget type (AwFormWidget).`);
        }
        return component;
      },
    },
    created () {
      const formComponent = this.findComponent('AwForm');
      if (!formComponent) {
        throw new Error('Can\'t find AwForm in parents component');
      }
      if (!this.findComponent('AwFormRow')) {
        this.modelValueData = {
          ...this.modelValue,
          validation: formComponent.modelValue,
        };
      }
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.formWidget {
  position: relative;
  display: block;

  &.flex {
    display: flex;
    align-items: center;

    :nth-child(1) {
      flex-grow: 1;
    }
  }
}
</style>
