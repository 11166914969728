<template>
  <div
    :class="[
      $style.awTinyRadio,
      {
        [$style.awTinyRadioDisabled]: disabled,
        [$style.awTinyRadioHorizontal]: direction === 'horizontal',
      },
    ]"
  >
    <fieldset class="awFieldsetContents">
      <legend class="awSrOnlyAbs" v-text="legendText" />
      <label
        v-for="option in options"
        :key="option[optionIdProperty]"
        :class="[
          'awHiddenInputFullSizeParent',
          $style.label,
          {
            [$style.labelSmall]: isSmall,
            [$style.labelSelected]: valueToCheckAgainst === option[optionIdProperty],
          },
        ]"
      >
        <input
          type="radio"
          :name="uuid"
          :value="option[optionIdProperty]"
          :checked="valueToCheckAgainst === option[optionIdProperty]"
          :aria-disabled="disabled"
          :class="['awHiddenInputFullSizeInput', $style.input]"
          @click="onClick"
          @change="onChange(option)"
        >
        {{ option[optionLabelProperty] }}
      </label>
    </fieldset>
  </div>
</template>
<script>
  import { uuid4 } from '~~/common/utils';

  export default {
    name: 'AwTinyRadio',
    props: {
      modelValue: {
        type: [Number, String, Object],
        required: true,
      },
      legendText: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        required: true,
      },
      optionIdProperty: {
        type: String,
        required: true,
      },
      optionLabelProperty: {
        type: String,
        required: true,
      },
      isSmall: {
        type: Boolean,
        required: true,
      },
      direction: {
        type: String,
        required: true,
        validator: v => v === 'horizontal' || v === 'vertical',
        default: 'vertical',
      },
    },
    emits: [
      'update:modelValue',
    ],
    data () {
      return {
        uuid: null,
      };
    },
    computed: {
      valueToCheckAgainst () {
        if (typeof this.modelValue === 'object' && this.modelValue) {
          return this.modelValue[this.optionIdProperty];
        } else {
          return this.modelValue;
        }
      },
    },
    mounted () {
      this.uuid = uuid4();
    },
    methods: {
      onClick (evt) {
        if (this.disabled) {
          evt.preventDefault();
          evt.stopImmediatePropagation();
        }
      },
      onChange (option) {
        this.$emit('update:modelValue', option);
      },
    },
  };
</script>
<style module lang="scss" rel="stylesheet/scss">
.awTinyRadio {
  display: flex;
  flex-direction: column;
  padding: 3px;
  border: 1px solid $color-border;
  border-radius: 12px;
  background: $color-white;
}

.label {
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  display: flex;
  justify-content: center;
  padding: 2px 3.5px;
  border: 0.5px solid $color-white;
  border-radius: 8px;
}

.labelSelected {
  border-color: $color-border;
  background: $color-background-1;
}

.labelSmall {
  font-size: 10px;
  padding: 2px 3px;
}

.awTinyRadioDisabled {
  cursor: not-allowed;
  opacity: 0.5;

  .input {
    cursor: not-allowed;
  }
}

.awTinyRadioHorizontal {
  flex-direction: row;
  width: fit-content;
  padding: 3px;
  border-radius: 8px;

  .label{
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 6px;
  }
}

.input:focus-visible {
  border-radius: inherit;
  box-shadow: 0 0 0 1px currentColor, 0 0 0 2px $color-white, 0 0 0 3px currentColor
}
</style>
