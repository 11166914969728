<template>
  <div :class="[$style.formRow, $style[rowSpace], {[$style.formRowFocused]: isFocused}]">
    <slot>
      <div :class="[$style.formRowControl]">
        <aw-form-label v-if="modelValue.label && !modelValue.outerLabel" v-model="modelValueData" :is-focused="isFocused">
          <template #before>
            <slot name="label-before" />
          </template>
          <template #after>
            <slot name="label-after" />
          </template>
        </aw-form-label>
        <label
          v-else-if="modelValue.outerLabel"
          :id="labelUniqueId"
          :class="[$style[modelValue.inputSize ? modelValue.inputSize : 'lg']]"
          :for="(modelValue.labelAttrs && modelValue.labelAttrs.for) || widgetUniqueId"
          v-html="labelValue"
        />
        <aw-form-widget
          v-model="modelValueData"
          :is-focused="isFocused"
          @focus="onWidgetFocus"
          @blur="onWidgetBlur"
          @typeahead-enter="$emit('typeahead-enter')"
        >
          <template #prefix>
            <slot name="prefix" />
          </template>
          <template #postfix>
            <slot name="postfix" />
          </template>
          <template #before>
            <slot name="widget-before" />
          </template>
          <template #after>
            <slot name="widget-after" />
          </template>
        </aw-form-widget>
      </div>
      <aw-form-error-notification v-if="modelValue.isBoxError" v-model="modelValueData" />
      <aw-form-error v-else v-model="modelValueData" :input-size="modelValue.inputSize" />
      <aw-form-success v-model="modelValueData" :input-size="modelValue.inputSize" />
    </slot>
  </div>
</template>

<script>
  import AwFormWidget from './AwFormWidget';
  import AwFormLabel from './AwFormLabel';
  import AwFormError from './AwFormError';
  import AwFormErrorNotification from './AwFormErrorNotification';
  import AwFormSuccess from './AwFormSuccess';
  import formMixin from '~~/common/mixins/formMixin';

  export default {
    name: 'AwFormRow',
    components: {
      AwFormSuccess,
      AwFormError,
      AwFormErrorNotification,
      AwFormLabel,
      AwFormWidget,
    },
    mixins: [formMixin],
    props: {
      rowSpace: {
        type: String,
        default: 'md',
        validator: val => ['none', 'md'].includes(val),
      },
    },
    emits: ['typeahead-enter'],
    data () {
      return {
        isFocused: false,
      };
    },
    created () {
      const formComponent = this.findComponent('AwForm');
      if (!formComponent) {
        throw new Error('Can\'t find AwForm in parents component');
      }
      this.modelValueData = {
        ...this.modelValue,
        validation: formComponent.modelValue,
      };
    },
    methods: {
      onWidgetFocus () {
        this.isFocused = true;
      },
      onWidgetBlur () {
        this.isFocused = false;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.formRow {
  &.md {
    margin-bottom: 18px;
  }

  &Control {
    position: relative;
  }

  label {
    &.xs {
      font-size: $font-size-lg;
    }

    &.md {
      font-size: $font-size-md;
    }

    &.lg {
      font-size: $font-size-xs;
    }
  }
}
</style>
