<template>
  <div>
    <div v-if="hasError && errorMessage" :class="$style.formError">
      <aw-notification
        :id="rowUniqueId"
        :notifications="notifications"
        :close-after="0"
        aria-live="assertive"
        @remove-notification="$event => delete notifications[$event]"
      />
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import formMixin from '~~/common/mixins/formMixin';

  const DEFAULT_ERROR_MESSAGES = {
    email: 'aw.common.form.validation.email',
    required: 'aw.common.form.validation.required',
    samePassword: 'aw.common.form.validation.same_password',
  };

  export default {
    name: 'AwFormErrorNotification',
    components: {
      AwNotification: defineAsyncComponent(() => import('~~/common/components/Common/AwNotification')),
    },
    mixins: [formMixin],
    data () {
      return {
        notifications: {},
      };
    },
    computed: {
      errorMessage () {
        return this.getErrorMessage();
      },
    },
    methods: {
      getErrorMessage () {
        if (this.rowValidator) {
          for (const key in this.rowValidator) {
            if (key.startsWith('$')) {
              continue;
            }
            if (!this.rowValidator?.[key]?.$invalid) {
              continue;
            }
            let msg;
            if (this.modelValue.tokens && this.hasProperty(this.modelValue.tokens, key)) {
              msg = this.modelValue.tokens[key];
            } else {
              msg = DEFAULT_ERROR_MESSAGES[key];
            }
            this.notifications = {
              0: {
                type: 'error',
                manualClose: false,
                text: {
                  subtitleToken: msg,
                },
              },
            };
            return msg;
          }
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.formError {
  font-size: 12px;
  line-height: 16px;
  position: relative;
  margin-top: 12px;
  color: $color-error-v2-text;
}
</style>
