<template>
  <div
    v-if="hasSuccess && successMessage"
    :class="[
      $style[`formSuccess${inputSize}`],{
        [$style.formSuccessVersion2]: modelValue.version2
      }
    ]"
  >
    {{ successMessage }}
  </div>
</template>

<script>
  import formMixin from '~~/common/mixins/formMixin';

  export default {
    name: 'AwFormSuccess',
    mixins: [formMixin],
    props: {
      inputSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
    },
    computed: {
      successMessage () {
        return this.modelValue.tokens?.success;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$input-sizes: (
  "xs": (
    "margin-top": 2px,
  ),
  "md": (
    "margin-top": 6px,
  ),
  "lg": (
    "margin-top": 12px,
  ),
);
@each $size, $values in $input-sizes {
  .formSuccess#{$size} {
    font-size: 12px;
    line-height: 16px;
    position: relative;
    z-index: 1;
    margin-top: map-get($values, "margin-top");
    color: $color-success;
  }
}

.formSuccess {
  color: $color-border-success;
}
</style>
