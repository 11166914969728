<template>
  <div
    :id="rowUniqueId"
    :class="[
      $style[`formError${inputSize}`],
      {
        [$style.formErrorVersion2]: modelValue.version2,
        [$style.formErrorVersion2Checkbox]: modelValue.type === 'checkbox',
        awSrOnlyAbs: !(hasError && errorMessage),
      }
    ]"
    aria-live="assertive"
  >
    <span v-if="hasError && errorMessage" v-text="errorMessage" />
  </div>
</template>

<script>
  import formMixin from '~~/common/mixins/formMixin';
  const hardcodedBeTokens = new Set(['backend', 'password', 'taxNumberValidator', 'phoneValidator', 'groupIdNumberValidator', 'ucbAddressValidator', 'applicationIdent', 'contractNumber']);

  const DEFAULT_ERROR_MESSAGES = {
    email: 'aw.common.form.validation.email',
    required: 'aw.common.form.validation.required',
    sameAsPassword: 'aw.common.form.validation.same_password',
    notSameAsOldPassword: 'aw.common.form.validation.not_same_as_old_password',
    sameAsEmail: 'aw.common.form.validation.same_email',
  };

  export default {
    name: 'AwFormError',
    mixins: [formMixin],
    props: {
      inputSize: {
        type: String,
        default: 'lg',
        validator: value => ['xs', 'md', 'lg'].includes(value),
      },
    },
    computed: {
      errorMessage () {
        return this.getErrorMessage();
      },
    },
    methods: {
      getErrorMessage () {
        if (this.rowValidator) {
          for (const key in this.rowValidator) {
            if (key.startsWith('$')) {
              continue;
            }
            if (!this.rowValidator?.[key]?.$invalid) {
              continue;
            }
            if (this.hasProperty(this.modelValue.tokens, key)) {
              if (hardcodedBeTokens.has(key)) {
                // Backendről már lefordított hibaüzenet jön vissza és a backendről jövő validátorok már lefordítva jönnek vissza
                return this.modelValue.tokens[key];
              }
              // if use key inside token, eg.: "hello {first_name}", then use 'Key' postfix in validator token name eg.: checkName -> checkNameKey
              return this.$awt(this.modelValue.tokens[key], this.modelValue.tokens[`${key}Key`] || undefined);
            } else {
              return this.$awt(DEFAULT_ERROR_MESSAGES[key]);
            }
          }
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$input-sizes: (
  "xs": (
    "margin-top": 2px,
  ),
  "md": (
    "margin-top": 6px,
  ),
  "lg": (
    "margin-top": 12px,
  ),
);
%formError {
  @include font(null, 12px, 16px);
  color: $color-error-v1-text;

  &:not(:global(.awSrOnlyAbs)) {
    position: relative;
  }
}
@each $size, $values in $input-sizes {
  .formError#{$size} {
    @extend %formError;
    margin-top: map-get($values, "margin-top");
  }
}

.formErrorVersion2 {
  @include font($font-weight-normal-v2, 14px, 20px);
  margin: 8px 0;
  color: $color-error-v2-text;

  &Checkbox {
    margin-right: 0;
    margin-left: 0;
  }
}
</style>
